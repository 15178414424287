import React, { FC, useEffect, useMemo, useState } from "react";
import { DateInput, DatePickerModifiers } from "@blueprintjs/datetime";
import { addMinutes, addYears, endOfDay, format, isSameDay, min, startOfDay } from "date-fns";
import { Page } from "../Page/Page";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Funnel,
    FunnelChart,
    LabelList,
    ResponsiveContainer,
    Tooltip,
    Trapezoid,
    XAxis,
    YAxis,
} from "recharts";
import { Callout, Card, H3, NumberRange, RangeSlider, Switch } from "@blueprintjs/core";
import { Analytics, Connection, getAnalytics, getShowNames, ShowDetails } from "../Client";
import { PaddedBox } from "../Components/PaddedBox";
import { ShowSelector } from "./ShowSelector";
import { useRequest } from "./useRequest";
import { DATE_ONLY, TIME_ONLY } from "../Components/Functions";
import { StreamSelector } from "./StreamSelector";
import { Stream, useAppState } from "../State";

import "./Dashboard.scss";

const funnelPreset = [
    {
        name: "Connected",
        fill: "#CED9E0",
        stroke: "#293742",
    },
    {
        name: "5 min",
        fill: "#BFCCD6",
        stroke: "#293742",
    },
    {
        name: "15 min",
        fill: "#A7B6C2",
        stroke: "#293742",
    },
    {
        name: "30 min",
        fill: "#8A9BA8",
        stroke: "#293742",
    },
    {
        name: "60 min",
        fill: "#738694",
        stroke: "#293742",
    },
    {
        name: "80 min",
        fill: "#5C7080",
        stroke: "#293742",
    },
];

const formatDate = (t: number | string) => format(new Date(t), TIME_ONLY);

const areaTooltip = (
    <Tooltip
        contentStyle={{
            backgroundColor: "#394B59",
            borderRadius: 4,
            borderColor: "#293742",
        }}
        itemStyle={{
            padding: 0,
        }}
        labelStyle={{
            fontWeight: 800,
            paddingBottom: 5,
        }}
        labelFormatter={formatDate}
    />
);

const funnelTooltip = (
    <Tooltip
        contentStyle={{
            backgroundColor: "#394B59",
            borderRadius: 4,
            borderColor: "#293742",
        }}
        itemStyle={{
            color: "#E1E8ED",
        }}
    />
);

interface ConnectedPhonesChartProps {
    data: Connection[];
    singleShow: boolean;
    domainRange: [number, number];
}

const ConnectedPhonesChart: FC<ConnectedPhonesChartProps> = (props) => {
    const { data, domainRange, singleShow } = props;
    const [range, setRange] = useState<NumberRange>([0, 100]);

    const [from, to] = domainRange;
    const diff = to - from;

    const atOffset = (rate: number) => from + (diff * rate) / 100;
    const domain: [number, number] = singleShow
        ? domainRange
        : [atOffset(range[0]), atOffset(range[1])];

    return (
        <Card className="ss-dashboard-chart">
            <H3>Connected phones</H3>
            {!singleShow && (
                <div className="ss-dashboard-slider">
                    <RangeSlider
                        value={range}
                        onChange={setRange}
                        min={0}
                        max={100}
                        labelStepSize={20}
                        labelRenderer={(i) => formatDate(atOffset(i))}
                    />
                </div>
            )}
            <ResponsiveContainer height={300}>
                <AreaChart
                    margin={{ top: 15, right: 30, left: -30, bottom: 20 }}
                    data={data}
                >
                    <XAxis
                        dataKey="time"
                        domain={domain}
                        name="Time"
                        tickFormatter={formatDate}
                        type="number"
                        tickSize={15}
                        allowDataOverflow={true}
                    />
                    <YAxis />
                    <CartesianGrid
                        strokeDasharray="1 1"
                        opacity={0.1}
                    />
                    {areaTooltip}
                    <Area
                        animationDuration={100}
                        type="stepAfter"
                        dataKey="android"
                        fillOpacity={1}
                        fill="#0D805066"
                        stroke="#3DCC91"
                        stackId={1}
                    />
                    <Area
                        animationDuration={100}
                        type="stepAfter"
                        dataKey="ios"
                        fillOpacity={1}
                        fill="#F5F8FA99"
                        stroke="#E1E8ED"
                        stackId={1}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Card>
    );
};

interface FunnelData {
    value: number;
    name: string;
    fill: string;
    stroke: string;
}

interface ConnectionTimeFunnelProps {
    data: FunnelData[];
}

const ConnectionTimeFunnel: FC<ConnectionTimeFunnelProps> = ({ data }) => (
    <Card className="ss-dashboard-chart">
        <H3>Connection time</H3>
        <ResponsiveContainer height={300}>
            <FunnelChart>
                {funnelTooltip}
                <Funnel
                    activeShape={<Trapezoid />}
                    dataKey="value"
                    data={data}
                    animationDuration={100}
                >
                    <LabelList
                        data={[]}
                        dataKey="name"
                    />
                </Funnel>
            </FunnelChart>
        </ResponsiveContainer>
    </Card>
);

const minDate = new Date("2019-12-01");

export const Dashboard: FC = () => {
    const { session } = useAppState();

    if (session == null) {
        return <></>;
    }

    return (
        <AuthorizedDashboard
            streams={session.streams}
            selectedStream={session.selectedStream}
        />
    );
};

interface AuthorizedDashboardProps {
    streams: Array<Stream>;
    selectedStream: Stream;
}

const AuthorizedDashboard: FC<AuthorizedDashboardProps> = ({ streams, selectedStream }) => {
    const [wholeDay, setWholeDay] = useState(true);
    const [error, setError] = useState<string | undefined>();
    const [shows, setShows] = useState<ShowDetails[] | undefined>();
    const { selectStream } = useAppState();

    const [selectedShow, setSelectedShow] = useState<ShowDetails | undefined>();
    const [date, setDate] = useState<Date>(new Date());

    const [analytics, setAnalytics] = useState<Analytics | undefined>();

    const funnelData = useMemo<Array<FunnelData> | false | undefined>(() => {
        const result = analytics?.totalTimes
            ?.filter((it) => it > 0)
            ?.map((value, i) => ({ ...funnelPreset[i], value }));

        return result?.length === 0 ? false : result;
    }, [analytics]);

    const [counter, setCounter] = useState(0);
    useEffect(() => {
        const interval =
            wholeDay && isSameDay(new Date(), date)
                ? setInterval(() => setCounter((c) => c + 1), 2 * 1000)
                : undefined;

        return () => {
            if (interval != null) {
                clearInterval(interval);
            }
        };
    }, [wholeDay, date]);

    const connections = useMemo(
        () => analytics?.connections?.map((c) => ({ ...c, time: c.time * 1000 })) ?? [],
        [analytics]
    );

    useRequest(
        () => getShowNames(selectedStream.id, addYears(new Date(), -10), new Date()),
        (result) => {
            setError(undefined);
            setShows(result);
        },
        () => {
            setError("Unable to load data");
        },
        [counter, selectedStream.id]
    );

    useRequest(
        () =>
            wholeDay || selectedShow == null
                ? getAnalytics(selectedStream.id, startOfDay(date), endOfDay(date))
                : getAnalytics(
                      selectedStream.id,
                      addMinutes(new Date(selectedShow.startedAt), -20),
                      selectedShow.finishedAt
                          ? addMinutes(new Date(selectedShow.finishedAt), 20)
                          : new Date()
                  ),
        (result) => {
            setError(undefined);
            setAnalytics(result);
        },
        () => {
            setError("Unable to load data");
        },
        [selectedShow, date, wholeDay, counter, selectedStream.id]
    );

    useEffect(() => {
        const interval = setInterval(() => {
            getShowNames(selectedStream.id, new Date(), new Date()).catch(() =>
                console.log("ping failed")
            );
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [selectedStream.id]);

    const onSelectedShowChange = (show: ShowDetails) => {
        setSelectedShow(show);
        setDate(new Date(show.startedAt));
    };

    const datesWithShows = useMemo(
        () =>
            shows
                ?.map((it) => format(new Date(it.startedAt), DATE_ONLY))
                ?.reduce<{ [key: string]: boolean }>((acc, el) => {
                    acc[el] = true;
                    return acc;
                }, {}) ?? {},
        [shows]
    );

    const calendarModifiers: DatePickerModifiers = {
        hasShows: (date) => datesWithShows[format(date, DATE_ONLY)],
    };

    return (
        <Page className="ss-dashboard">
            <PaddedBox
                className="ss-dashboard-actions"
                vertical={true}
            >
                <div>
                    <Switch
                        checked={wholeDay}
                        onChange={(e) => setWholeDay(e.currentTarget.checked)}
                    >
                        Whole day
                    </Switch>
                </div>
                <PaddedBox vertical={false}>
                    {streams.length > 1 && (
                        <div>
                            <StreamSelector
                                items={streams}
                                selected={selectedStream}
                                onChange={selectStream}
                            />
                        </div>
                    )}
                    {wholeDay && (
                        <DateInput
                            formatDate={(date) => format(date, DATE_ONLY)}
                            parseDate={(str) => (str ? new Date(str) : null)}
                            onChange={setDate}
                            value={date}
                            maxDate={new Date()}
                            disabled={!wholeDay}
                            popoverProps={{ boundary: "window" }}
                            modifiers={calendarModifiers}
                            reverseMonthAndYearMenus={true}
                            dayPickerProps={{ firstDayOfWeek: 1 }}
                            minDate={minDate}
                            canClearSelection={false}
                        />
                    )}
                    {!wholeDay && (
                        <ShowSelector
                            items={shows ?? []}
                            disabled={wholeDay}
                            selected={selectedShow}
                            onChange={onSelectedShowChange}
                        />
                    )}
                </PaddedBox>
            </PaddedBox>
            {error && (
                <Callout
                    intent="danger"
                    title="Error"
                    icon="error"
                >
                    {error}
                </Callout>
            )}
            {funnelData === false && (
                <Callout
                    intent="warning"
                    icon="warning-sign"
                >
                    No phones connected in a selected period
                </Callout>
            )}
            {connections.length > 0 && (
                <ConnectedPhonesChart
                    data={connections}
                    domainRange={
                        wholeDay || selectedShow == null || connections.length < 1
                            ? [
                                  startOfDay(date).getTime(),
                                  min([endOfDay(date), new Date()]).getTime(),
                              ]
                            : [connections[0]?.time, connections[connections.length - 1].time]
                    }
                    singleShow={!wholeDay && selectedShow != null}
                />
            )}
            {funnelData && <ConnectionTimeFunnel data={funnelData} />}
        </Page>
    );
};
