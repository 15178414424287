import React, { FC } from "react";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import { Stream } from "../State";

export function formatTitle(item: Stream): string {
    if (item.subName != null) return `${item.name} - ${item.subName}`;
    else return item.name;
}

interface StreamSelectorProps {
    items: Stream[];
    selected: Stream;
    onChange: (item: Stream) => void;
    disabled?: boolean;
}

function search(query: string, stream: Stream): boolean {
    return `${stream.name} ${stream.subName ?? ""}`.toLowerCase().includes(query.toLowerCase());
}

export const StreamSelector: FC<StreamSelectorProps> = (props) => {
    const { items, selected, onChange, disabled } = props;

    return (
        <Select<Stream>
            items={items}
            disabled={disabled}
            itemPredicate={search}
            itemRenderer={(item, props) => (
                <MenuItem
                    onClick={props.handleClick}
                    active={props.modifiers.active}
                    disabled={props.modifiers.disabled}
                    key={item.id}
                    text={formatTitle(item)}
                />
            )}
            noResults={
                <MenuItem
                    disabled={true}
                    text="No results."
                />
            }
            onItemSelect={onChange}
            popoverProps={{ minimal: true, boundary: "window" }}
        >
            <Button
                text={selected ? formatTitle(selected) : "Select a stream..."}
                rightIcon="double-caret-vertical"
                icon={selected ? undefined : "list"}
                disabled={disabled}
            />
        </Select>
    );
};
