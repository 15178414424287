import React, { useEffect } from "react";
import { isUnauthorized, useSignOut } from "../Client";

export function useRequest<T>(
    fn: () => Promise<T>,
    onSuccess: (t: T) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => void,
    deps: React.DependencyList
): void {
    const signOut = useSignOut();
    useEffect(() => {
        let cancelled = false;

        fn()
            .then((r) => {
                if (!cancelled) onSuccess(r);
            })
            .catch((e) => {
                if (!cancelled) {
                    onError(e);
                    if (isUnauthorized(e)) {
                        signOut();
                    }
                }
            });

        return () => {
            cancelled = true;
        };
        // eslint-disable-next-line
    }, [...deps]);
}
