import React, { FC } from "react";
import cn from "classnames";

import "./PaddedBox.scss";

interface PaddedBoxProps {
    vertical?: boolean;
    className?: string;
    children: React.ReactNode;
}

export const PaddedBox: FC<PaddedBoxProps> = ({ children, vertical, className }) => {
    return (
        <div className={cn("ss-padded-box", className, { "ss-padded-box-vertical": vertical })}>
            {children}
        </div>
    );
};
