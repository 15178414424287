import React, { FC } from "react";
import "./ActionBar.scss";
import { Button, Divider } from "@blueprintjs/core";
import { useSignOut } from "../../Client";
import { formatTitle } from "../../Dashboard/StreamSelector";
import { useAppState } from "../../State";

interface IActionBarProps {
    children?: React.ReactNode;
}
export const ActionBar: FC<IActionBarProps> = ({ children }) => {
    const onLogOut = useSignOut();
    const stream = useAppState().session?.selectedStream;

    const title: string = stream != null ? formatTitle(stream) : "Analytics";

    return (
        <div className="ss-actionbar">
            <h3>{title}</h3>
            <div className="ss-actionbar-body">{children}</div>
            <Divider />
            <Button
                icon="log-out"
                minimal={true}
                onClick={onLogOut}
            />
        </div>
    );
};
