import React, { FC } from "react";
import { ActionBar } from "./ActionBar/ActionBar";
import cn from "classnames";

import "./Page.scss";

interface PageProps {
    className?: string;
    actionBar?: React.ReactElement;
    children: React.ReactNode;
}

export const Page: FC<PageProps> = ({ className, actionBar, children }) => {
    return (
        <>
            <ActionBar>{actionBar}</ActionBar>
            <div className="ss-page-container">
                <div className={cn("ss-page", className)}>{children}</div>
            </div>
        </>
    );
};
