import React, { FC, FormEvent, useState } from "react";
import { useAppState } from "../State";
import { Button, Callout, Card, FormGroup, H2, InputGroup } from "@blueprintjs/core";

import "./LoginPage.scss";
import { logIn } from "../Client";

function inputOnChange(
    onChange: (s: string) => void
): (e: React.FormEvent<HTMLInputElement>) => void {
    return (e) => onChange(e.currentTarget.value);
}

export const LoginPage: FC = () => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | undefined>();
    const { signIn } = useAppState();

    const onSubmit = () => {
        setLoading(true);

        logIn(username, password)
            .then((streams) => {
                signIn(streams);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setError("Invalid credentials");
                setLoading(false);
            });
    };

    return (
        <div className="ss-login-page">
            <Card className="ss-login-page-card">
                <H2>Substreamer Analytics</H2>
                <form>
                    <FormGroup>
                        <InputGroup
                            placeholder="Username"
                            large={true}
                            disabled={loading}
                            value={username}
                            onChange={inputOnChange(setUsername)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputGroup
                            placeholder="Password"
                            large={true}
                            disabled={loading}
                            type="password"
                            value={password}
                            onChange={inputOnChange(setPassword)}
                        />
                    </FormGroup>
                    {error && (
                        <FormGroup>
                            <Callout intent="danger">{error}</Callout>
                        </FormGroup>
                    )}
                    <Button
                        large={true}
                        onClick={onSubmit}
                        disabled={loading}
                        type="submit"
                    >
                        Sign In
                    </Button>
                </form>
            </Card>
        </div>
    );
};
