import { format } from "date-fns";
import { Stream, useAppState } from "./State";
import { DATE_ONLY } from "./Components/Functions";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8080";

function handleErrors(r: Response): Response {
    if (r.ok) {
        return r;
    }
    throw r;
}

export function isUnauthorized(resp: Response): boolean {
    return resp.status === 403;
}

export function logIn(username: string, password: string): Promise<Array<Stream>> {
    return fetch(`${BACKEND_URL}/analytics/login`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
    })
        .then(handleErrors)
        .then((r) => r.json())
        .then((r: Array<Stream>) => r);
}

export function logOut(): Promise<Response> {
    return fetch(`${BACKEND_URL}/analytics/logout`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
    }).then(handleErrors);
}

export function useSignOut(): () => void {
    const { signOut } = useAppState();
    return () => {
        signOut();
        logOut().catch((e) => {
            console.log("Can't sign out", e);
        });
    };
}

export interface ShowDetails {
    id: number;
    name: string;
    startedAt: string;
    finishedAt?: string;
}

export function getShowNames(
    streamId: string,
    dateFrom: Date,
    dateTo: Date
): Promise<Array<ShowDetails>> {
    const from = format(dateFrom, DATE_ONLY);
    const to = format(dateTo, DATE_ONLY);

    return fetch(`${BACKEND_URL}/analytics/shows/${streamId}?dateFrom=${from}&dateTo=${to}`, {
        credentials: "include",
        mode: "cors",
    })
        .then(handleErrors)
        .then((r) => r.json())
        .then((r: Array<ShowDetails>) => r);
}

export interface Connection {
    time: number;
    android: number;
    ios: number;
}

export interface Analytics {
    connections: Connection[];
    totalTimes: number[];
}

export function getAnalytics(streamId: string, dateFrom: Date, dateTo: Date): Promise<Analytics> {
    const from = dateFrom.toISOString();
    const to = dateTo.toISOString();

    return fetch(`${BACKEND_URL}/analytics/data/${streamId}?dateFrom=${from}&dateTo=${to}`, {
        credentials: "include",
        mode: "cors",
    })
        .then(handleErrors)
        .then((r) => r.json())
        .then((r: Analytics) => r);
}
