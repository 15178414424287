import { ShowDetails } from "../Client";
import React, { FC } from "react";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import { format } from "date-fns";
import { DATE_TIME } from "../Components/Functions";

const getTitle = (item: ShowDetails) =>
    `${format(new Date(item.startedAt), DATE_TIME)} - ${item.name}`;

interface ShowSelectorProps {
    items: ShowDetails[];
    selected?: ShowDetails;
    onChange: (item: ShowDetails) => void;
    disabled?: boolean;
}

export const ShowSelector: FC<ShowSelectorProps> = (props) => {
    const { items, selected, onChange, disabled } = props;

    return (
        <Select<ShowDetails>
            items={items}
            disabled={disabled}
            itemPredicate={(item, show) => show.name.toLowerCase().includes(item.toLowerCase())}
            itemRenderer={(item, props) => (
                <MenuItem
                    onClick={props.handleClick}
                    active={props.modifiers.active}
                    disabled={props.modifiers.disabled}
                    key={item.id}
                    text={getTitle(item)}
                />
            )}
            noResults={
                <MenuItem
                    disabled={true}
                    text="No results."
                />
            }
            onItemSelect={onChange}
            popoverProps={{ minimal: true, boundary: "window" }}
        >
            <Button
                text={selected ? getTitle(selected) : "Select a show..."}
                rightIcon="double-caret-vertical"
                icon={selected ? undefined : "calendar"}
                disabled={disabled}
            />
        </Select>
    );
};
