import create, { State, StateCreator } from "zustand";

export interface Stream {
    id: string;
    name: string;
    subName?: string;
}

export interface Session {
    selectedStream: Stream;
    streams: Array<Stream>;
}

type AppState = {
    session: Session | undefined;
    selectStream: (stream: Stream) => void;
    signIn: (streams: Array<Stream>) => void;
    signOut: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const restoredState: Partial<AppState> = JSON.parse(localStorage.getItem("state") ?? "{}");

const persist =
    <T extends State>(config: StateCreator<T>): StateCreator<T> =>
    (set, get, api) =>
        config(
            (args) => {
                set(args);
                window.localStorage.setItem("state", JSON.stringify(get()));
            },
            get,
            api,
            []
        );

export const useAppState = create<AppState>(
    persist((set) => ({
        session: undefined,
        ...restoredState,
        selectStream: (selectedStream: Stream) =>
            set((old: AppState): Partial<AppState> => {
                if (old.session != null) return { session: { ...old.session, selectedStream } };
                else return old;
            }),
        signIn: (streams: Array<Stream>) =>
            set({ session: { streams, selectedStream: streams[0] } }),
        signOut: () => set({ session: undefined }),
    }))
);

export const useAuthorized = (): boolean => useAppState().session != null;
