import React, { FC, useCallback, useEffect, useState } from "react";
import { LoginPage } from "./LoginPage/LoginPage";
import { LocationTuple, Redirect, Route, Router, Switch, useLocation } from "wouter";
import { Dashboard } from "./Dashboard/Dashboard";
import { useAuthorized } from "./State";
import { NonIdealState } from "@blueprintjs/core";

const currentLocation = () => window.location.hash.replace(/^#/, "") || "/";

const useHashLocation = (): LocationTuple => {
    const [loc, setLoc] = useState(currentLocation());

    useEffect(() => {
        const handler = () => setLoc(currentLocation());
        window.addEventListener("hashchange", handler);
        return () => {
            window.removeEventListener("hashchange", handler);
        };
    }, []);

    const navigate = useCallback((to: string) => {
        window.location.hash = to;
    }, []);

    return [loc, navigate];
};

interface IRedirectHandlerProps {
    children: React.ReactNode;
}

const RedirectHandler: FC<IRedirectHandlerProps> = ({ children }) => {
    const authorized = useAuthorized();
    const [location] = useLocation();

    if (!authorized && location !== "/login") {
        return <Redirect to="/login" />;
    }
    if ((authorized && location === "/login") || location === "/") {
        return <Redirect to="/dashboard" />;
    }

    return <>{children}</>;
};

const App: FC = () => {
    return (
        <Router hook={useHashLocation}>
            <RedirectHandler>
                <Switch>
                    <Route
                        path="/dashboard"
                        component={Dashboard}
                    />
                    <Route
                        path="/login"
                        component={LoginPage}
                    />
                    <Route path="/:rest*">
                        <NonIdealState
                            icon="error"
                            title="Not Found"
                            description="The page you were looking for could not be found"
                        />
                    </Route>
                </Switch>
            </RedirectHandler>
        </Router>
    );
};

export default App;
